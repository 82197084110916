import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import { decodeEntities } from '../utils/htmlParse';
import SEO from '../components/SEO';
import './sitemap.scss';

const SitemapPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle: title
        }
      }
      allWordpressPage(filter: {slug: {ne: "gatsby-build-page"}}) {
        nodes {
          title
          slug
          link
          parent_element {
            slug
          }
        }
      }
      allWordpressWpProject {
        nodes {
          title
          link
        }
      }
    }
  `);
  const { allWordpressPage, allWordpressWpProject, site } = data;
  const { siteMetadata: { siteTitle } } = site;
  const parentPages = allWordpressPage.nodes.filter(page => page.parent_element === null);
  const sitemapItems = parentPages.map(item => {
    const children = allWordpressPage.nodes.filter(page => {
      if (!page.parent_element) return null;
      return page.parent_element.slug === item.slug;
    });
    return children.length > 0 ? {...item, children} : item;
  });
  return (
    <Layout location={location}>
      <SEO title={`Sitemap | ${siteTitle}`} />
      <section className="sitemap-page">
        <div className="wrapper">
          <h1>
            Sitemap
          </h1>
          <nav>
            {sitemapItems.map(item => {
              return (
                <div className="sitemap-item">
                  <Link to={item.link}>{decodeEntities(item.title)}</Link>
                  {item.children && (
                    <div className="sitemap-item-children">
                      {item.children.map(child => {
                        return (
                          <div className="sitemap-item-child">
                            <Link
                              to={child.link}
                            >
                              {decodeEntities(child.title)}
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {item.slug === 'projects' && (
                    <div className="sitemap-item-children">
                      {allWordpressWpProject.nodes.map(project => {
                        return (
                          <div className="sitemap-item-child">
                            <Link
                              to={project.link}
                            >
                              {decodeEntities(project.title)}
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </nav>
        </div>
      </section>
    </Layout>
  );
}

export default SitemapPage
